import kotlinx.browser.document
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.asList

const val DEFAULT_TAG = "/tags/tag.svg"
fun main() {
    console.log("Hello Kotlin/JS")

    document.querySelectorAll("img.icon").asList().forEach {
        it.addEventListener("error", { evt ->
            val img = evt.target.unsafeCast<HTMLImageElement>()
            console.log(img.src)
            if (img.src != DEFAULT_TAG) {
                img.src = DEFAULT_TAG
            }
        })
    }
}
